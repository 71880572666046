import { header } from '../../portfolio'
import Navbar from '../Navbar/Navbar'
import './Header.css'
// import logo from './assets'
// import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import About from '../../components/About/About'

const Header = () => {
  const { homepage, title } = header

  return (
    <header className='header center'>
    <img src='https://flashmike.com/images/flashmike_head_web.jpg' alt='flashmike' width='80px'></img>
      <h3>
        {homepage ? (
          <a href={homepage} className='link resized'>
            {title}
          </a>
        ) : (
          title
        )}
      </h3>
      <Navbar />
      {/* <BrowserRouter>
        <Routes>
          <Route path="/motion-graphics" element={<About />} />
        </Routes>
      </BrowserRouter> */}
    </header>
  )
}

export default Header
