const header = {
  homepage: "https://web.flashmike.com",
  // title: '{ web }',
};

const about = {
  name: "web.FlashMike",
  role: "Web / Full Stack Development",
  description: "Responsive, well-designed, and functional.",
  resume: "https://flashmike.com",
  social: {
    // linkedin: 'https://www.linkedin.com/in/mike-mijaro-2a35a7239/',
    github: "https://github.com/flashmike",
    // youtube: 'https://www.youtube.com/user/flashwebworks/videos',
  },
};

const web = [
  {
    name: "Kultura Trip",
    image: "https://flashmike.com/contents/web/kultura_trip.jpg",
    alt: "Kultura Trip",
    description:
      "A multi-level hyper-casual game created in Unity and Android showcased in this website.",
    stack: ["HTML5", "CSS", "JS", "Bootstrap"],
    livePreview: "https://flashmike.com/KulturaTrip/",
  },
  {
    name: "My Home",
    image: "https://flashmike.com/contents/apps/myhome.jpg",
    alt: "My Home",
    description: "Real Estate website based on my UX case study.",
    stack: ["React", "Vite", "JSX"],
    livePreview: "https://web.flashmike.com/myHome",
  },
  {
    name: "iLugaw",
    image: "https://flashmike.com/contents/apps/ilugaw.jpg",
    alt: "iLugaw",
    description:
      "Web and mobile app development for an online food ordering system.",
    stack: ["React", "UI/UX", "Android"],
    livePreview: "https://flashmike.github.io/react-ilugaw/",
  },
  {
    name: "One Nature",
    image: "https://flashmike.com/contents/apps/onenature.jpg",
    alt: "One Nature",
    description:
      "React-based One Nature website with online booking system version 2.",
    stack: ["React", "Vite", "JSX"],
    livePreview: "https://web.flashmike.com/onh",
  },
  {
    name: "Harvest Tours",
    image: "https://flashmike.com/contents/web/harvesttours.jpg",
    alt: "Harvest Tours",
    description:
      "Australia-based tour operator providing the best wine-tastings in the country.",
    stack: ["WIX", "Custom Theme"],
    livePreview: "https://www.harvesttours.com.au/",
  },
  {
    name: "IKAD Engineering",
    image: "https://flashmike.com/contents/web/ikad.jpg",
    alt: "IKAD Engineering",
    description:
      "Diverse engineering company offering marine, mechanical & engineering project management and services.",
    stack: ["HTML5", "CSS", "JS", "Bootstrap", "PHP"],
    livePreview: "https://ikad.com.au/",
  },
  {
    name: "Triconstruct",
    image: "https://flashmike.com/contents/apps/triconstruct.jpg",
    alt: "Triconstruct",
    description:
      "Multinational contracting company in Qatar providing construction, technical & management services.",
    stack: ["React", "JS"],
    livePreview: "https://triconstruct.com",
  },
  {
    name: "LD Records",
    image: "https://flashmike.com/contents/web/ldr.jpg",
    alt: "Lonesome Day Records",
    description:
      "Music recording & services company for up and coming artists.",
    stack: ["Wordpress", "Custom Theme"],
    livePreview: "https://www.lonesomeday.com/",
  },
  {
    name: "A to Z Air",
    image: "https://flashmike.com/contents/web/atoz.jpg",
    alt: "A to Z Air",
    description:
      "Air-conditioning solutions provider renowned for their superior services and workmanship.",
    stack: ["HTML5", "CSS", "JS", "Bootstrap"],
    livePreview: "https://atozair.com.au/",
  },
  {
    name: "Amber DXB",
    image: "https://flashmike.com/contents/web/amberdxb.jpg",
    alt: "Amber DXB",
    description:
      "Providing women the luxury of international fashion with a comfortable approach and affordable price tag in the UAE.",
    stack: ["e-Commerce", "HTML5", "CSS", "JS", "PHP", "Bootstrap"],
    livePreview: "https://amberdxb.com/",
  },
  {
    name: "Big Toys For Little People",
    image: "https://flashmike.com/contents/web/bigtoys.jpg",
    alt: "Big Toys For Little People",
    description: "Toys & play equipment providers for all your party needs.",
    stack: ["Wordpress", "Custom Theme", "Graphics"],
    livePreview: "https://www.bigtoysforlittlepeople.com.au/",
  },
  {
    name: "Mix Plumbing",
    image: "https://flashmike.com/contents/web/mixplumbing.jpg",
    alt: "Mix Plumbing",
    description:
      "General & specialized plumbing services provider including renovations and repairs based in Australia.",
    stack: ["HTML5", "CSS", "JS", "Bootstrap"],
    livePreview: "https://www.mixplumbing.com.au/",
  },
  {
    name: "Pernix Group",
    image: "https://flashmike.com/contents/web/pernix.jpg",
    alt: "Pernix Group",
    description:
      "Leading construction company offering diversified general contracting & management services to clients and agencies.",
    stack: ["Wordpress"],
    livePreview: "https://www.pernixgroup.com/",
  },
  {
    name: "Zion Global",
    image: "https://flashmike.com/contents/web/zion.jpg",
    alt: "Zion Global",
    description:
      "eCommerce and digital marketing experts providing coaching and webinars.",
    stack: ["Wordpress", "Graphics"],
    livePreview: "https://www.zionglobalmarketing.com/",
  },
  {
    name: "Ellenbrook",
    image: "https://flashmike.com/contents/web/esc.jpg",
    alt: "Ellenbrook",
    description:
      "Secondary college providing positive and enriching learning programs.",
    stack: ["Wordpress", "Graphics"],
    livePreview: "http://ellenbrooksc.wa.edu.au/",
  },
  {
    name: "Red Apple Media",
    image: "https://flashmike.com/contents/web/redapple.jpg",
    alt: "Red Apple Media",
    description: "Managed web hosting and media delivery service provider.",
    stack: ["HTML5", "CSS", "JS", "Joomla"],
    livePreview: "https://www.redapplemedia.com/",
  },
  {
    name: "Cabinet Noir",
    image: "https://flashmike.com/contents/web/cabinetnoir.jpg",
    alt: "Cabinet Noir",
    description: "Leading men's fashion provider in Australia.",
    stack: ["Wordpress", "Custom Theme", "Graphics"],
    livePreview: "https://cabinetnoir.com.au/",
  },
  {
    name: "Montebello",
    image: "https://flashmike.com/contents/web/montebello.jpg",
    alt: "Montebello",
    description:
      "Best charter company operating fishing, diving and surfing safaris in Montebello Island.",
    stack: ["SquareSpace", "Graphics"],
    livePreview: "https://www.montebello.com.au/",
  },
  {
    name: "Morgan Creek",
    image: "https://flashmike.com/contents/web/morgan.jpg",
    alt: "Morgan Creek",
    description:
      "With Odoo ERP, investment adviser providing investment management services to institutions and wealthy families.",
    stack: ["Wordpress", "Odoo"],
    livePreview: "https://www.morgancreekcap.com/",
  },
  {
    name: "Travellers List",
    image: "https://flashmike.com/contents/web/tl.jpg",
    alt: "Travellers List",
    description: "Providers of customized travel experiences.",
    stack: ["HTML5", "CSS", "JS", "Bootstrap"],
    livePreview: "https://travellerslist.com/",
  },
  {
    name: "Haliburg",
    image: "https://flashmike.com/contents/web/haliburg.jpg",
    alt: "Haliburg",
    description:
      "Multinational conglomerate of brands and offices that covers a highly diverse range of industries.",
    stack: ["Wordpress", "Divi", "Graphics"],
    livePreview: "https://haliburg.com/",
  },
  {
    name: "ONH Foundation",
    image: "https://flashmike.com/contents/web/onfoundation.jpg",
    alt: "ONH Foundation",
    description:
      "Established to help curb the effects of on-going threats to the environment.",
    stack: ["HTML5", "CSS", "JS", "Bootstrap", "Graphics"],
    livePreview: "https://onenaturehotelsfoundation.org/",
  },
  {
    name: "Nael Medical",
    image: "https://flashmike.com/contents/web/nael.jpg",
    alt: "Nael Medical",
    description:
      "Leading distributor of a wide range of clinical equipment and products.",
    stack: ["Wordpress", "Divi", "Graphics"],
    livePreview: "https://naelmedical.com/",
  },
  {
    name: "Supremo Games",
    image: "https://flashmike.com/contents/web/supremogames.jpg",
    alt: "Supremo Games",
    description: "Collection of Filipino-made digital mobile apps and games.",
    stack: ["HTML5", "CSS", "JS", "Bootstrap", "Graphics"],
    // livePreview: '#',
  },
  /* {
    name: 'Giga Flapps',
    image: 'https://flashmike.com/contents/web/gigaflapp.jpg',
    alt: 'Giga Flapps',
    description:
      'Collection of mobile game and user apps designed and developed in Unity 3D and Android Studio.',
    stack: ['HTML5', 'CSS', 'JS', 'Bootstrap', 'Graphics'],
    // livePreview: '#',
  }, */
  {
    name: "Envicon",
    image: "https://flashmike.com/contents/web/envicon.jpg",
    alt: "Envicon",
    description:
      "Mechanical and electrical engineering service providers in the UAE.",
    stack: ["HTML5", "CSS", "JS", "Bootstrap", "Graphics"],
    livePreview: "https://envicon.ae/",
  },
  {
    name: "Gulf Continental Oil",
    image: "https://flashmike.com/contents/web/gconoil.jpg",
    alt: "Gulf Continental Oil",
    description: "One of the fastest growing lubricant companies in the UAE.",
    stack: ["HTML5", "Bootstrap", "Graphics"],
    livePreview: "https://gconoil.com/",
  },
  {
    name: "Petrovoll",
    image: "https://flashmike.com/contents/web/petrovoll.jpg",
    alt: "Petrovoll",
    description:
      "Superior quality lubricant line of products providing ultra-protection for automotive, heavy industrial machinery, & marine equipment.",
    stack: ["HTML5", "CSS", "JS", "Bootstrap", "Graphics"],
    livePreview: "https://petrovoll.com/dev/",
  },
  {
    name: "Tripta",
    image: "https://flashmike.com/contents/web/tripta.jpg",
    alt: "Tripta",
    description:
      "Originally created the interactive online digital porftolio and catalogue for the #1 realtor in the Polo Club since 1995.",
    stack: ["Wordpress", "Custom Theme", "UI/UX"],
    livePreview: "https://tripta.langrealty.net/",
  },
  {
    name: "Dubai Metro Maids",
    image: "https://flashmike.com/contents/web/dubaimetromaids.jpg",
    alt: "Dubai Metro Maids",
    description:
      "Reliable office and house cleaning service providers in the UAE.",
    stack: ["HTML5", "CSS", "JS", "Bootstrap", "Graphics"],
    livePreview: "https://dubaimetromaids.com/",
  },
];

const skills = [
  // 'HTML',
  // 'CSS',
  // 'JavaScript',
  // 'TypeScript',
  // 'React',
  // 'Redux',
  // 'SASS',
  // 'Material UI',
  // 'Git',
  // 'CI/CD',
  // 'Jest',
  // 'Enzyme',
];

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: "mike@flashmike.com",
};

export { about, contact, header, skills, web };
