import uniqid from 'uniqid'
import ComputerIcon from '@mui/icons-material/Computer';
import './WebContainer.css'

const WebContainer = ({ web }) => (
  <div className='web'>
    {<a href={web.livePreview} target='_blank' rel='noopener noreferrer' >
      <img src={web.image} alt={web.alt} width='90%'></img>
    </a>}

    <a
      href={web.livePreview}
      aria-label='live preview'
      className='link'
      target='_blank'
      rel='noopener noreferrer'
    ><h4 className='p-b-20'>{web.name}</h4></a>

    <p className='web__description'>{web.description}</p>

    {web.stack && (
      <ul className='web__stack'>
        {web.stack.map((item) => (
          <li key={uniqid()} className='web__stack-item'>
            {item}
          </li>
        ))}
      </ul>
    )}

    {web.livePreview && (
      <a
        href={web.livePreview}
        aria-label='live preview'
        className='link link--icon'
        target='_blank'
        rel='noopener noreferrer'
      >
        <ComputerIcon />
      </a>
    )}
  </div>
)

export default WebContainer
