import uniqid from 'uniqid'
import { web } from '../../portfolio'
import WebContainer from '../WebContainer/WebContainer'
import './Web.css'
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';

const Web = () => {
  if (!web.length) return null

  return (
    // <section id='web' className='section web'>
    <section id='web' className='section'>
      <h3 className='section__title'><PersonalVideoIcon /> Web Works</h3>

      <div className='web__grid'>
        {web.map((web) => (
          <WebContainer key={uniqid()} web={web} />
        ))}
      </div>
    </section>
  )
}

export default Web
